// import {Notyf} from 'notyf';

class Notyf {
	constructor() {
		this.container = document.querySelector('.new-form__info');
	}

	_messageTemplate(message, state) {
		const cssClass = state === 'valid' ? 'is-valid' : 'is-invalid';
		const svg = `
			<svg width="16" height="16" viewBox="-0.5 -0.5 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="8" cy="8" r="8" fill="#21A038"/>
				<path d="M4 7.81818L7.5 11L13 6" stroke="white" stroke-linecap="round"/>
			</svg>
		`;
		const icon = state === 'valid' ? svg : '';
		this.container.classList.add(cssClass);

		this.container.innerHTML = `${icon}${message}`;
	}

	error(text) {
		this._messageTemplate(text);
	}

	success(text) {
		this._messageTemplate(text, 'valid');
	}
}

let notyf = new Notyf();

const sendMail = (url, data, target) => {
	fetch(url, {
		method: 'POST',
		body: data,
	}).then((response) => {
		console.log(response);
		
		if (response.ok) {
			return response.json();
		}
		throw new Error();
	}).then((responseJson) => {
		// console.log(responseJson)
		if (responseJson.status === 'success') {
			target.reset();
			notyf.success('Сообщение успешно отправлено');
			// document.querySelector('.popup-application__success').classList.remove('is-hidden');
			// document.querySelector('.popup-application__form').classList.add('is-hidden');
		} else {
			notyf.error(responseJson.message);
		}
	}).catch((err) => {
		// console.error(err);
		notyf.error('Произошла ошибка отправки!');
	});
};

const sendForm = (evt) => {
	let formData = new FormData(evt.target);
	sendMail(evt.target.action, formData, evt.target);
};

const baseSuccessCallback = (evt) => {
	evt.preventDefault();
	console.log(evt);

	if (window.smartCaptcha) {
		document.querySelector('#captcha-container').classList.add('is-visible');

		const key = document.querySelector('#captcha-container').dataset.sitekey;
		const container = document.getElementById('captcha-container');
		const widgetId = window.smartCaptcha.render(container, {
			sitekey: key,
			hl: 'ru',
			callback: (token) => {
				document.querySelectorAll('input[name="smart-token"]').forEach((el) => {
					el.value = token;
				});
			},
		});
		const success = window.smartCaptcha.subscribe(widgetId, 'success', () => {
			sendForm(evt);
			document.querySelector('#captcha-container').classList.remove('is-visible');
		});
	} else {
		sendForm(evt);
	}

	// В данном колбеке бэкендер, либо разработчик при необходимости будет писать запрос на отправку формы
	// на сервер и обрабатывать возможные ошибки или успешную отправку формы на сервер
};

const baseErrorCallback = (event) => {
	event.preventDefault();
	// Данный коллбек используется при необходимости выполнить какое-либо действие помимо показа ошибок при
	// попытке отправить неккорректные данные, он не связан с запросами на сервер
	// notyf.error({
	// 	message: 'Обязательные поля не заполнены',
	// });
};

export const callbacks = {
	base: {
		// Сбросс формы
		// reset: true,
		// Таймаут сброса формы
		resetTimeout: 500,
		successCallback: baseSuccessCallback,
		errorCallback: baseErrorCallback,
	},
};
