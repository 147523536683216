import './vendor';
import './helpers';
import './components/social';
import {ieFix} from './vendor/ie-fix';
import {vhFix} from './vendor/vh-fix';
import {actualYear} from './modules/actualYear';
import video from './components/video';
import calendar from "./components/calendar";
import lazyLoading from './modules/lazyLoading';
//import scrollToAnchor from './modules/scrollToAnchor';
import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination, Grid} from 'swiper/modules';
import daterangepicker from './vendor/daterangepicker';
import select2 from 'select2';
import vars from "./helpers";
import AirDatepicker from 'air-datepicker';
import el from "air-datepicker/locale/el";
import newForm from './components/newForm/newForm';
import {createEvent} from 'ics';
import preloader from './components/preloader';

preloader.init();
ieFix();
vhFix();
actualYear();
//scrollToAnchor.init();

lazyLoading.init();

const $document = $(document);
const $window = $(window);
const $html = $('html');
const $body = $('body');

let app = {
	global: {
		init() {
			video.init();
			calendar.init();
			newForm.init();
		},
	},
	introSlider: {
		init($module) {
			const slider = new Swiper($module.find('.swiper')[0], {
				modules: [Autoplay, Navigation, Pagination],
				// autoHeight: true,
				slidesPerView: 1,
				spaceBetween: 10,
				loop: true,
				autoplay: {
					delay: 3000,
					disableOnInteraction: false,
					// pauseOnMouseEnter: true,
				},
				navigation: {
					prevEl: $module.find('.home__intro-arrow-prev')[0],
					nextEl: $module.find('.home__intro-arrow-next')[0],
				},

				on: {
					init: () => {
						$module.find('.home__intro-pagination')[0].textContent = `1/${$module.find(".swiper-slide").length}`;
					},
					slideChange: () => {
						$module.find('.home__intro-pagination')[0].textContent = `${slider.realIndex + 1}/${$module.find(".swiper-slide").length}`;
					},
					autoplayTimeLeft(s, time, progress) {
						$module.find('.swiper-pagination-bullet-active').css('--progress', `${(1 - progress) * 100}%`);
					}
				},
				pagination: {
					el: $module.find('.home__intro-progressbar')[0],
				},
			});
		},
	},
	filters: {
		init($module) {
			const $calendarItem = $module.find('.home__filters-calendar');
			const $calendar = $module.find('.home__filters-calendar input');
			const $selects = $module.find('.home__filters-select select');
			const $view = $module.find('.home__filters-select--view');
			const $events = $module.find('.home__filters-select--events');
			const $tag = $module.find('.home__filters-tag');
			let clickCount = 0;
			let tagLeft = 0;

			const datepicker = new AirDatepicker($calendar[0], {
				classes: 'datepicker',
				range: true,
				multipleDatesSeparator: ' - ',
				autoClose: true,
				// inline: true,
				buttons: ['clear'],
				locale: {
					clear: 'Сброс'
				},
				dateFormat: 'yyyy-MM-dd',
				onHide: function onHide(isFinished) {
					if (isFinished) {
						$calendarItem.removeClass('is-active');
						clickCount = 0;
						setfilterrs(1);
					}
				},
				navTitles: {
					days: '<p>MMMM</p>',
				},
				prevHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="6" height="8" viewBox="0 0 6 8" fill="none"><path d="M0.5 4L5.75 0.968911L5.75 7.03109L0.5 4Z" fill="#252525"/></svg>',
				nextHtml: '<svg xmlns="http://www.w3.org/2000/svg" width="6" height="8" viewBox="0 0 6 8" fill="none"><path d="M5.5 4L0.25 7.03109L0.25 0.968911L5.5 4Z" fill="#252525"/></svg>',
				onSelect: ({date, formattedDate, datepicker}) => {
					if(formattedDate.length) {
						let start = formattedDate[0];
						let end = formattedDate[1];

						window.calendar.setOption('validRange', {
							start: start,
							end: end ? moment(formattedDate[1]).add(1, 'days').format("YYYY-MM-DD") : start,
						});

						$('.fc-list-table .fc-list-day').each(function () {
							$(this).prev('.fc-list-event').addClass('is-last');
						})
					} else {
						window.calendar.setOption('validRange');
					}
				}
			})

			$calendarItem.on('click', (e) => {
				e.preventDefault();
			});
			
			$calendar.on('click', () => {
				clickCount++;
				$calendarItem.addClass('is-active');

				if (clickCount === 2) {
					datepicker.hide();
					$calendarItem.removeClass('is-active');
					clickCount = 0;
				}
			});

			let isClose = false;
			let isAntiDoubleClick = false;
			$('[data-select-open]').on('click', function() {
				if (!isAntiDoubleClick) {
					const id = $(this).data('select-open');				
					if ($(this).hasClass('is-open')) {
						$(`#${id}`).select2('close');
						$(this).removeClass('is-open');
					} else {
						if (!isClose) {
							$(`#${id}`).select2('open');
							$(this).addClass('is-open');
						}
					}

					isAntiDoubleClick = true;

					setTimeout(() => {
						isAntiDoubleClick = false;
					}, 100);
				}
			});

			$('#select-events').on('select2:closing', () => {
				$('[data-select-open]').removeClass('is-open');

				isClose = true;
				setTimeout(() => {
					isClose = false;
				}, 100);
			});

			if ($tag[0]) {
				$tag.on('click', () => {
					$tag.toggleClass('is-active');
				});

				$document.on('click', (e) => {
					let $target = $(e.target);

					if (!$target.closest('.home__filters-tag').length) {
						$tag.removeClass('is-active');
					}
				})

				if (vars.isMobile()) {
					if ($module[0].getBoundingClientRect().left === $tag[0].getBoundingClientRect().left) {
						tagLeft = 0
					} else {
						tagLeft = $tag[0].getBoundingClientRect().left - $module[0].getBoundingClientRect().left;
					}
					$module.find('.home__filters-tag').find('.home__filters-tag-wrapper').css('left', `-${tagLeft}px`);
				}
			}

			// const homeTitle = document.querySelector('.home__hero').querySelector('.home__title').getBoundingClientRect();

			// $calendar.daterangepicker({
			// 	locale: {
			// 		cancelLabel: 'Clear',
			// 		format: 'DD.MM.YYYY',
			// 		daysOfWeek: [
			// 			'Вс',
			// 			'Пн',
			// 			'Вт',
			// 			'Ср',
			// 			'Чт',
			// 			'Пт',
			// 			'Сб',
			// 		],
			// 		monthNames: [
			// 			'Январь',
			// 			'Февраль',
			// 			'Март',
			// 			'Апрель',
			// 			'Май',
			// 			'Июнь',
			// 			'Июль',
			// 			'Август',
			// 			'Сентябрь',
			// 			'Октябрь',
			// 			'Ноябрь',
			// 			'Декабрь',
			// 		],
			// 		//firstDay: 1,
			// 	},
			// 	autoApply: true,
			// 	autoUpdateInput: false,
			// 	linkedCalendars: false,
			// 	//minDate: new Date(),
			// 	parentEl: $calendar.parent(),
			// 	startDate: new Date(),
			// }, function(start, end, label) {
			// 	//console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
			// });
			//
			// $calendar.on('apply.daterangepicker', function(ev, picker) {
			// 	$(this).val(picker.startDate.format('DD.MM.YYYY') + ' - ' + picker.endDate.format('DD.MM.YYYY'));
			// });

			$selects.each((index, item) => {
				const $select = $(item);
				console.log(item);
				
				$select.select2({
					minimumResultsForSearch: Infinity,
					// dropdownParent: $select.parent(),
					escapeMarkup: function(markup) {
						return markup;
					},
					dropdownAutoWidth : true,
					width: '100%'
				});
				
				if (item.getAttribute('class').includes('select-links'))
				$select.on('select2:select', function(e) {
					const href = e.params.data.element.dataset.href;
					window.open(href, '_blank');			
				});
			});

			const toogleCalendar = (value) => {
				$('[data-tabs="' + value + '"]').removeClass('is-hidden').siblings().addClass('is-hidden');				
				if (value === 'calendar') {
					calendar.init();
					$events.hide();
					$calendarItem.hide();

				} else {
					$events.show();
					$calendarItem.show();
				}
			};

			$('[data-view]').on('click', function (e) {
				if(!vars.isMobile()) {
					let value = e.currentTarget.dataset.view;

					toogleCalendar(value);
				}
			});

			$view.on('change', function (e) {
				if(!vars.isMobile()) {
					let value = e.target.value;

					toogleCalendar(value);
				}
			});

			if(vars.isMobile()) {
				$(`[data-tabs="news"]`).hide();
				$(`[data-tabs="calendar"]`).show();

				// $calendar.attr('placeholder', 'Выбрать даты');
				$('.home__calendar').removeClass('is-hidden');
			}
		}
	},
	partnersSlider: {
		init($module) {
			let length = 5;
			let rows = 1;
			let height = false;
			let fill = 'column';

			if (vars.isMobile()) {
				length = 4
			}

			if ($module.find(".swiper-slide").length > length) {
				rows = 2;
				fill = 'row';
			} else {
				height = true;
			}

			const slider = new Swiper($module.find('.swiper')[0], {
				modules: [Navigation, Pagination, Grid],
				slidesPerView: 4,
				autoHeight: height,
				grid: {
					rows: rows,
					fill: fill,
				},
				breakpoints: {
					1024: {
						slidesPerView: 5,
					},
				},
				navigation: {
					prevEl: $module.find('.detail__partners-arrow-prev')[0],
					nextEl: $module.find('.detail__partners-arrow-next')[0],
				},
				pagination: {
					el: $module.find('.detail__partners-progressbar')[0],
					type: 'bullets',
				},
			});
		},
	},
	detailSlider: {
		init($module) {
			const slider = new Swiper($module.find('.swiper')[0], {
				modules: [Navigation],
				slidesPerView: 1,
				spaceBetween: 10,
				allowTouchMove: !$module.data('slider'),
				breakpoints: {
					1024: {
						slidesPerView: $module.data('slider') ? 1 : 2,
						spaceBetween: 20,
					},
				},
				navigation: {
					prevEl: $module.find('.detail__slider-arrow-prev')[0],
					nextEl: $module.find('.detail__slider-arrow-next')[0],
				},
			});

			if($module.find('.swiper-slide').length === 1) {
				$module.find('.detail__slider-arrows').find('button').addClass('is-hidden')
				// $('.swiper-wrapper').addClass( "disabled" );
				// $('.swiper-pagination').addClass( "disabled" );
			}

			slider.on('slideChange', () => {
				$module.find('.video-js video').each((index, item) => {
					item.pause();
				});
			});
		},
	},
	detailSections: {
		init($module) {
			const $itemHidden = $module.find('.detail__sections-item--hidden');
			const $more = $module.find('.detail__sections-more a');
			const $buton = $module.find('.detail__sections-item-button');

			$more.on('click', (e) => {
				e.preventDefault();

				$itemHidden.removeClass('detail__sections-item--hidden');
				$more.closest('.detail__sections-more').addClass('is-hidden');
			})

			$buton.on('click', (e) => {
				e.preventDefault();

				const $item = $(e.currentTarget).closest('.detail__sections-item');
				const $main = $item.find('.detail__sections-main');
				const $span = $(e.currentTarget).find('span');
				
				$item.toggleClass('is-active');

				const setText = $item.hasClass('is-active') ? 'Свернуть' : 'Подробнее';
				
				$main.slideToggle(300);
				$span.text(setText);
			})
		},
	},
}

app.global.init();

window.initApps = () => {
	$('[data-app]').each((index, item) => {
		let $this = $(item);
		let split = $this.data('app').split('||');

		$.each(split, (appIndex, appName) => {
			let appNameCamel = false;

			if (appName.indexOf('-') !== -1) {
				appNameCamel = appName.replace(/(-|\s)(\S)/ug, (m) => m.toUpperCase()).replace(/-/ug, '');
			}

			if (app[appName] && $this.data(`${appName}-init`) !== true) {
				app[appName].init($this);
				$this.data(`${appName}-init`, true);
			} else if (app[appNameCamel] && $this.data(`${appName}-init`) !== true) {
				app[appNameCamel].init($this);
				$this.data(`${appName}-init`, true);
			}
		});
	});
};

initApps();



const getMonthIndex = (month) => {	
	const months = {
			'января': 0,
			'февраля': 1,
			'марта': 2,
			'апреля': 3,
			'мая': 4,
			'июня': 5,
			'июля': 6,
			'августа': 7,
			'сентября': 8,
			'октября': 9,
			'ноября': 10,
			'декабря': 11
	};
	return months[month] !== undefined ? months[month] : -1;
}

const isValidDate = (date) => {
	return date instanceof Date && !isNaN(date);
}

const extractDates = (dateRange) => {
	const regex = /(\d{1,2})\s*-\s*(\d{1,2})\s+([а-яА-ЯёЁ]+)|(\d{1,2})\s+([а-яА-ЯёЁ]+)/;
	const match = dateRange.match(regex);

	if (match) {
			const monthIndex = getMonthIndex(match[3] || match[6] || match[5]);
			const year = new Date().getFullYear();

			let startDate, endDate;
			if (match[1] && match[2]) {
					const startDay = match[1];
					const endDay = match[2];
					startDate = new Date(year, monthIndex, startDay);
					endDate = new Date(year, monthIndex, endDay);
			} else {
					const startDay = match[4];
					const endDay = match[6];
					startDate = new Date(year, monthIndex, startDay);
					const endMonthIndex = getMonthIndex(match[7]);
					endDate = new Date(year, endMonthIndex, endDay);
			}
			startDate = isValidDate(startDate) ? startDate : (new Date());
			endDate = isValidDate(endDate) ? endDate : null;
			
			return {
					startDate,
					endDate
			};
	}

	return null;
}

const createEventObj = (button) => {
	const card = button.closest('.home__news-item-content');

	if (!card) {
		return null;
	}

	const title = card.querySelector('.home__news-item-title');
	const desc = card.querySelector('.home__news-item-text');
	const locationEvent = card.querySelector('.home__news-item-city span');
	const dates = card.querySelector('.home__news-item-date') ? extractDates(card.querySelector('.home__news-item-date').innerHTML) : { startDate: null, endDate: null };
	const link = card.querySelector('.home__news-item-link a');
	
	let event = {
		title: title && title.innerHTML ? title.innerHTML : '',
		description: desc && desc.innerHTML ? desc.innerHTML : '',
		location: locationEvent && locationEvent.innerHTML ? locationEvent.innerHTML : '',
		url: location.host.includes('localhost:3000') ? 'https://data-economy-calendar.linestest.com/' : link.getAttribute('href'),
		status: 'CONFIRMED',
		busyStatus: 'BUSY',
		alarms: [
			{
				action: 'display',
				trigger: { days: -1, hours: -1 },
				repeat: 5
			}
		]
	};

	if (dates.startDate && dates.endDate) {
		const durationDays = Math.ceil((dates.endDate - dates.startDate) / (1000 * 60 * 60 * 24));
		event.start = [dates.startDate.getFullYear(), dates.startDate.getMonth() + 1, dates.startDate.getDate()];
		event.duration = { days: durationDays + 1, hours: 1};
	} else {
		const startDate = isValidDate(dates.startDate) ? dates.startDate : new Date();
		event.start = [dates.startDate.getFullYear(), dates.startDate.getMonth() + 1, dates.startDate.getDate()];
	}
	console.log(event);
	
	return event;
}

const sendDataToCalendar = () => {
	const iconsCalendar = document.querySelectorAll('.home__news-item-calendar');
	const popup = document.querySelector('.popup');
	const html = document.querySelector('html');
	if(!iconsCalendar || !iconsCalendar.length === 0 || !popup) {
		return;
	}

	const classNameActive = 'popup--is-open';
	const classNameLockScroll = 'is-lock-scroll-v2'
	const backDrop = popup.querySelector('.popup__backdrop');
	const close = popup.querySelector('.popup__close');
	const cancel = popup.querySelector('.popup__cancel');
	const save = popup.querySelector('.popup__save');
	let newIcon = null;

	const closePopup = () => {
		popup.classList.remove(classNameActive);
		html.classList.remove(classNameLockScroll);
	}

	backDrop.addEventListener('click', () => {
		closePopup();
	});
	close.addEventListener('click', () => {
		closePopup();
	});
	cancel.addEventListener('click', () => {
		closePopup();
	});
	save.addEventListener('click', () => {
		if (!newIcon) {
			return
		}

		createEvent(createEventObj(newIcon), (error, value) => {
			if (error) {
					console.log(error);
					return;
			}
			 const blob = new Blob([value], { type: 'text/calendar' });
			 const link = document.createElement('a');
			 link.href = URL.createObjectURL(blob);
			 link.download = 'event.ics';

			 document.body.appendChild(link);
			 link.click();
			 document.body.removeChild(link);
		});
		closePopup();
	});

	iconsCalendar.forEach(icon => {
		icon.addEventListener('click', () => {
			popup.classList.add(classNameActive);
			html.classList.add(classNameLockScroll);
			newIcon = icon;
		})
	});
}

sendDataToCalendar();

