
function init(container) {
	const classNameScroll = 'is-lock-scroll'
	const html = document.querySelector(`.${classNameScroll}`);
	const preloader = document.querySelector('.preloader');

	if (!html || !preloader) {
		return;
	}

	const classNameHide = 'preloader--is-hidden';
	setTimeout(() => {
		preloader.classList.add(classNameHide);
		html.classList.remove(classNameScroll);
	}, 500);
}

function destroy(container) {

}

export default {
	init,
	destroy,
};
