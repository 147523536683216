// import {CustomSelect} from './select/custom-select';
import {Form} from './form-validate/form';
import {uploadImageDrop} from './input-file/init-upload';
import AirDatepicker from 'air-datepicker'

const init = () => {
	uploadImageDrop();
	// const select = new CustomSelect();
	// select.init();
	const form = new Form();
	window.form = form;
	form.init();

	const dataPickers = document.querySelectorAll('[data-picker]');
	const dataPickersArray = [];

	dataPickers.forEach((item) => {
		const input = item.querySelector('input');

		const datapicker = new AirDatepicker(input, {
			classes: 'datepicker',
			range: true,
			multipleDatesSeparator: ' - ',
			autoClose: true,
			buttons: ['clear'],
			locale: {
				clear: 'Сброс'
			},
		});

		dataPickersArray.push(datapicker);
	});
};

export default {
	init,
};
