function init(container) {
	let earliestEvent = {};
	earliestEvent.start = new Date();
	let nowDate = null;

	function initialView() {
		if (jQuery(window).width() >= 1025) {
			return 'dayGridMonth';
		}
		if (jQuery(window).width() < 1025) {
			return 'listMonth';
		}
	}

	function findEarlistDate(events) {
		earliestEvent = events.reduce((earliest, current) => {
			return new Date(current.start) < new Date(earliest.start) ? current : earliest;
		});

		const now = new Date();
		const upcomingEvent = events
			.filter(event => new Date(event.start) > now) 
			.sort((a, b) => new Date(a.start) - new Date(b.start))[0];

		nowDate = upcomingEvent.start;
	}

	function checkPlacesCard() {
		console.log('checkPlacesCard');
		
		const containers = document.querySelectorAll('.home__news-item-content');
		
		if (!containers || containers.length === 0) {
			return
		}

		containers.forEach(card => {
			const category = card.querySelector('.home__news-item-category');
			const link = card.querySelector('.home__news-item-category a');
			if (category && link && link.innerHTML.trim() === '') {
				console.log(link.innerHTML);
				card.removeChild(category);
			}
		});
	}

	const monthNames = ["ЯНВ", "ФЕВ", "МАР", "АПР", "МАЙ", "ИЮН",
		"ИЮЛ", "АВГ", "СЕН", "ОКТ", "НОЯ", "ДЕК"
	];

	let calendarEl = document.getElementById('event-calendar');

	if(calendarEl) {
		let calendar = new FullCalendar.Calendar(calendarEl, {
			initialView: initialView(),
			customButtons: {
				categoryList: {
					text: 'custom!',
					click: function () {

					}
				}
			},
			locale: 'ru',
			headerToolbar: {
				left: 'prev title next',
				center: '',
				right: ''
			},
			footerToolbar: false,
			buttonText: {
				today: 'Сегодня',
				month: 'Месяц',
				week: 'Неделя',
				day: 'День',
				list: 'Неделя'
			},
			allDayText: 'Весь день',
			editable: false,
			selectable: false,
			dayHeaders: true,
			dayCellContent: function (arg) {		
				arg.dayNumberText = arg.date.getDate() + ' ' + monthNames[arg.date.getMonth()].toLowerCase();
			},
			moreLinkContent: function (arg) {
				arg.text = '+ еще ' + arg.num;
			},
			contentHeight: 'auto',
			expandRows: true,
			firstDay: 1,
			slotMinTime: '06:00',
			slotMaxTime: '24:00',
			navLinks: false,
			nowIndicator: false,
			noEventsText: 'В выбранное время мероприятий нет.',
			dayMaxEvents: true,
			dayMaxEventRows: 5,
			fixedWeekCount: false,
			eventTimeFormat: {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false
			},
			displayEventEnd: true,
			eventOrder: "-duration",
			eventDidMount: function(info) {
				let eventStart = new Date(info.event.start).toISOString().slice(0, 10);
				let eventEnd = new Date(info.event.end).toISOString().slice(0, 10);				
				let dayCell = info.el.closest('.fc-daygrid-day');
				let dayCellDate = new Date($(info.el).closest('.fc-daygrid-day').data('date'));

				let weekStart = $(info.el).closest('tr').find('.fc-daygrid-day:first-child').data('date');
				let weekEnd = $(info.el).closest('tr').find('.fc-daygrid-day:last-child').data('date');

				let events = calendar.getEvents();
				let dayEvents = events.filter(function(event) {
					return event.start.toDateString() === info.event.start.toDateString();
				})

				let eventCount = dayEvents.length;

				if (dayCell) {
					if (eventCount >= 4) {
						dayCell.classList.add('fc-day-event-more');

						if($(dayCell).hasClass('fc-day-event-more')) {
							/*
							setTimeout(function (){
								if($(dayCell).find('.fc-day-one-day-events')) {
									$(dayCell).find('.fc-day-one-day-events').remove();
									$(dayCell).find('.fc-daygrid-day-events').append('<div class="fc-day-one-day-events"></div>');

									$(dayCell).find('.fc-daygrid-event-harness:not(.fc-daygrid-event-harness-abs)').appendTo($('.fc-day-one-day-events'));
								}
							},50)
							*/
						} else {
							console.log(2)
						}
					}
					if (eventStart === eventEnd) {
						info.el.classList.add('fc-day-one-day-event');
					}
					if (eventStart === weekStart) {
						info.el.classList.add('fc-day-week-start-event');
					}
					if (eventEnd === weekEnd) {
						info.el.classList.add('fc-day-week-end-event');
					}
				}

				if($(info.el).closest('.fc-daygrid-event-harness').hasClass('fc-daygrid-event-harness-abs')) {
					let startDate = new Date(info.event.start);
					startDate.setHours(0, 0, 0, 0);
					let endDate = new Date(info.event.end);
					endDate.setHours(23, 59, 59, 999);

					let eventDuration = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1;

					let weekStart = new Date($(info.el).closest('tr').find('.fc-daygrid-day:first-child').data('date'));
					let weekEnd = new Date($(info.el).closest('tr').find('.fc-daygrid-day:last-child').data('date'));
					weekEnd.setHours(23, 59, 59, 999);

					let daysInWeek = 0;

					if (startDate <= weekStart && endDate >= weekEnd) {
						daysInWeek = 7;
					} else if (startDate <= weekStart && endDate >= weekStart && endDate <= weekEnd) {
						daysInWeek = (endDate - weekStart) / (1000 * 3600 * 24) + 1;
					} else if (startDate >= weekStart && endDate <= weekEnd){
						daysInWeek = eventDuration;
					} else if (startDate <= weekEnd && endDate >= weekEnd && startDate >= weekStart) {
						daysInWeek = (weekEnd - startDate) / (1000 * 3600 * 24) + 1;
					}

					daysInWeek = Math.ceil(daysInWeek) !== 7 ? Math.ceil(daysInWeek) - 1 : 7;

					let classes = info.el.className;

					let eventLinks = '';
					for (let i=0; i< daysInWeek; i++) {
						let dats = moment(dayCellDate).toDate();
						let dayCellDateString = moment(dats).add(i, 'days').format("YYYY-MM-DD");

						eventLinks+= `<a href="${info.event._def.url}" class="fc-event-main-link" data-date="${dayCellDateString}"></a>`;
					}

					$(info.el).closest('.fc-daygrid-event-harness').html(
						`
							<div
								class="${classes}"
								style="background-color: ${info.backgroundColor};"
							>
								<div class="fc-event-main-links">${eventLinks}</div>
								<div class="fc-event-main">
									<div class="fc-event-main-frame">
										<div class="fc-event-title-container">
											<div class="fc-event-title fc-sticky">${info.event._def.title}</div>
										</div>
									</div>
								</div>
							</div>
						`
					);
					setTimeout(() => {
						$(info.el).remove();
					}, 5);
				}
			},
			eventClick: function (info) {				
				let dayCell = info.el.closest('.fc-daygrid-day');
				let dayCellDate = new Date($(info.el).closest('.fc-daygrid-day').data('date'));
				let dayCellDateString = new Date(dayCellDate).toDateString();

				let events = calendar.getEvents();
				let dayEvents = events.filter(function (event) {
					let start = new Date(event._instance.range.start).toDateString();
					let end = new Date(event._instance.range.end).toDateString();
					return Date.parse(start) <= Date.parse(dayCellDateString) && Date.parse(end) >= Date.parse(dayCellDateString);
				});				
				let sortedEvents = dayEvents.sort(function(a, b) {					
					let durationA = moment(a.end).diff(moment(a.start));
					let durationB = moment(b.end).diff(moment(b.start));
					return durationB - durationA;
				});

				let eventCount = dayEvents.length;

				if (dayCell) {
					if (eventCount >= 2) {
						info.jsEvent.preventDefault();

						$('.fc-popover').remove();

						let day = $(info.el).closest('.fc-daygrid-day');
						let dayDate = day.find('.fc-daygrid-day-number').text();
						let eventsHtml = sortedEvents.map((event) => {
							return `
								<div class="fc-daygrid-event-harness">
									<a
										href="${event.url}"
										target="_blank"
										class="fc-daygrid-event fc-daygrid-block-event fc-h-event fc-event fc-event-start fc-event-end fc-event-future"
										style="background-color: ${event.backgroundColor};"
									>
										<div class="fc-event-main">
											<div class="fc-event-main-frame">
												<div class="fc-event-time">09:30 - 17:00</div>
												<div class="fc-event-title-container">
													<div class="fc-event-title fc-sticky">${event.title}</div>
												</div>
											</div>
										</div>
									</a>
								</div>
							`;
						});

						let popup =
							`
								<div class="fc-popover fc-more-popover fc-day fc-day-tue fc-day-future">
									<div class="fc-popover-header ">
										<span class="fc-popover-title">${dayDate}</span>
										<span class="fc-popover-close fc-icon fc-icon-x"></span>
									</div>
									<div class="fc-popover-body">${eventsHtml.join('')}</div>
									<div class="fc-popover-bg"></div>
								</div>
						`;

						day.append(popup);

						let popover = $('.fc-popover');

						let dayRect = day[0].getBoundingClientRect();
						let containerRect = $('.fc-daygrid-body')[0].getBoundingClientRect();
						let hoverTop;
						let hoverLeft;

						if (dayRect.bottom + popover.outerHeight() > containerRect.bottom) {
							hoverTop = (dayRect.bottom - dayRect.top) + dayRect.top - containerRect.top - popover.outerHeight() - 10; // нижняя граница
						} else {
							hoverTop = dayRect.top - containerRect.top; // верхняя граница
						}

						if (dayRect.right + popover.outerWidth() > containerRect.right) {
							hoverLeft = dayRect.left - containerRect.left - popover.outerWidth() - 10; // Влево
						} else if (dayRect.left - popover.outerWidth() < containerRect.left) {
							hoverLeft = dayRect.left - containerRect.left + dayRect.width; // Вправо
						} else {
							hoverLeft = dayRect.left - containerRect.left + dayRect.width; // Вправо
						}

						popover.css({ top: hoverTop, left: hoverLeft });

					} else if (info.event.url) {
						info.jsEvent.preventDefault();

						window.open(info.event.url, "_blank");
						return false;
					}
				}
			},
			events: function (info, successCallback) {
				// let doc = [
				// 	{
				// 		"id": "358",
				// 		"title": "Форум регионов России и Беларуси",
				// 		"backgroundColor": "#CF2B64",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#CF2B64",
				// 		"color": "#ffffff",
				// 		"start": "2023-06-26T00:00:00",
				// 		"end": "2023-06-28T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://x-forum-region.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "364",
				// 		"title": "Форум «СИЛЬНЫЕ ИДЕИ ДЛЯ НОВОГО ВРЕМЕНИ»",
				// 		"backgroundColor": "#1E91D0",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#1E91D0",
				// 		"color": "#ffffff",
				// 		"start": "2023-06-28T00:00:00",
				// 		"end": "2023-06-29T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://ideas-forum.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "366",
				// 		"title": "Российская электроника",
				// 		"backgroundColor": "#3957A1",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#3957A1",
				// 		"color": "#ffffff",
				// 		"start": "2023-06-28T00:00:00",
				// 		"end": "2023-06-28T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://events.vedomosti.ru/events/electronics23?utm_source=vedomosticonf&utm_medium=emailman&utm_campaign=electronics23",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "362",
				// 		"title": "Shanghai New International Expo Centre (SNIEC)",
				// 		"backgroundColor": "#A43A78",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#A43A78",
				// 		"color": "#ffffff",
				// 		"start": "2023-06-28T00:00:00",
				// 		"end": "2023-06-30T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.mwcshanghai.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "360",
				// 		"title": "Летний HR-форум",
				// 		"backgroundColor": "#464DD8",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#464DD8",
				// 		"color": "#ffffff",
				// 		"start": "2023-06-30T00:00:00",
				// 		"end": "2023-06-30T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://events.kommersant.ru/events/hr-forum-06/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "369",
				// 		"title": "Южный форум информационной безопасности",
				// 		"backgroundColor": "#E21D5A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E21D5A",
				// 		"color": "#ffffff",
				// 		"start": "2023-07-04T00:00:00",
				// 		"end": "2023-07-07T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://infoforum.ru/infoforum-sochi-2023",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "371",
				// 		"title": "Международная конференция по квантовым технологиям (ICQT)",
				// 		"backgroundColor": "#66B331",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#66B331",
				// 		"color": "#ffffff",
				// 		"start": "2023-07-09T00:00:00",
				// 		"end": "2023-07-14T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://roscongress.org/events/forum-tekhnologiy-budushchego-kvantovyy-mir/about/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "375",
				// 		"title": "ИННОПРОМ 2023",
				// 		"backgroundColor": "#DDC06A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#DDC06A",
				// 		"color": "#ffffff",
				// 		"start": "2023-07-10T00:00:00",
				// 		"end": "2023-07-13T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://expo.innoprom.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "54",
				// 		"title": "Демо-день ИЦК «Химия»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-07-18T00:00:00",
				// 		"end": "2023-07-18T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/voenno-tehnicheskij-forum-armija-2023/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "379",
				// 		"title": "Конференция «Цифра для гуманитариев»",
				// 		"backgroundColor": "#C458E7",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#C458E7",
				// 		"color": "#ffffff",
				// 		"start": "2023-07-19T00:00:00",
				// 		"end": "2023-07-22T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://mgimo.ru/about/news/announce/dd-kazan-07-23/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "385",
				// 		"title": "Форум «Умный город — Умная страна» Уфа",
				// 		"backgroundColor": "#016B83",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#016B83",
				// 		"color": "#ffffff",
				// 		"start": "2023-07-26T00:00:00",
				// 		"end": "2023-07-28T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://forumsmartcity.ru/ufa",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "381",
				// 		"title": "Саммит экономический и гуманитарный форум РОССИЯ – АФРИКА",
				// 		"backgroundColor": "#CFC830",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#CFC830",
				// 		"color": "#ffffff",
				// 		"start": "2023-07-27T00:00:00",
				// 		"end": "2023-07-28T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://summitafrica.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "354",
				// 		"title": "Форум «ТАКСИ»",
				// 		"backgroundColor": "#FFD931",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#FFD931",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-03T00:00:00",
				// 		"end": "2023-08-04T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://meft.info/conf2023/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "356",
				// 		"title": "Форум Цифровая эволюция",
				// 		"backgroundColor": "#E4051D",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E4051D",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-10T00:00:00",
				// 		"end": "2023-08-11T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://цифроваяэволюция.рф/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "368",
				// 		"title": "Онлайн-митап “APM-мониторинг с помощью GMonit“",
				// 		"backgroundColor": "#D9E2EC",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#D9E2EC",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-10T00:00:00",
				// 		"end": "2023-08-10T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://gmonit.ru/blog/meetup-apm-monitoring",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "373",
				// 		"title": "Военно-технический форум «АРМИЯ-2023»",
				// 		"backgroundColor": "#E01F53",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E01F53",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-14T00:00:00",
				// 		"end": "2023-08-20T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://rusarmyexpo.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "97",
				// 		"title": "Демо-день ИЦК «Геологоразведка. Недропользование»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-16T00:00:00",
				// 		"end": "2023-08-16T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/demo-den-ick-geologorazvedka-nedropolzovanie/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "378",
				// 		"title": "Innopolis AI Conference",
				// 		"backgroundColor": "#8336FA",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#8336FA",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-17T00:00:00",
				// 		"end": "2023-08-18T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://aiconf.innopolis.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "384",
				// 		"title": "Международный форум технологического развития ТЕХНОПРОМ-2023",
				// 		"backgroundColor": "#B936DA",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#B936DA",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-22T00:00:00",
				// 		"end": "2023-08-25T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://форумтехнопром.рф/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "388",
				// 		"title": "Молодежный форум ИТ-технологий «ФИЧА»",
				// 		"backgroundColor": "#D5E2FE",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#D5E2FE",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-23T00:00:00",
				// 		"end": "2023-08-27T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://ficha.pro/#rec593999588",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "57",
				// 		"title": "Демо-день ИЦК «ЖКХ»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-23T00:00:00",
				// 		"end": "2023-08-23T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/hcs1/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "390",
				// 		"title": "B20 India Summit 2023",
				// 		"backgroundColor": "#F8931E",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#F8931E",
				// 		"color": "#ffffff",
				// 		"start": "2023-08-25T00:00:00",
				// 		"end": "2023-08-27T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://b20india2023.org/new-homepage",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "59",
				// 		"title": "Демо-день ИЦК «Автомобилестроение»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-07T00:00:00",
				// 		"end": "2023-09-07T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://ицкавто.рф/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "322",
				// 		"title": "Восточный экономический форум",
				// 		"backgroundColor": "#1E91D0",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#1E91D0",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-10T00:00:00",
				// 		"end": "2023-09-13T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://forumvostok.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "325",
				// 		"title": "Конференция по проблематике ​инфраструктуры открытых ключей и электронной подписи",
				// 		"backgroundColor": "#1E91D0",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#1E91D0",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-12T00:00:00",
				// 		"end": "2023-09-14T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://pki-forum.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "323",
				// 		"title": "Форум «Код Трансформации. Цифровая эволюция железных дорог»",
				// 		"backgroundColor": "#8E4A00",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#8E4A00",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-14T00:00:00",
				// 		"end": "2023-09-15T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://kod.rzddigital.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "329",
				// 		"title": "Форум SMART OIL &amp; GAS",
				// 		"backgroundColor": "#1E91D0",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#1E91D0",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-14T00:00:00",
				// 		"end": "2023-09-15T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.comnews-conferences.ru/ru/conference/smartoilgas2023",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "339",
				// 		"title": "Большие данные и бизнес-аналитика",
				// 		"backgroundColor": "#18A195",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#18A195",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-14T00:00:00",
				// 		"end": "2023-09-14T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://events.cnews.ru/events/bolshie_dannye_i_biznes_analitika__chto_jdet_rynok_k_koncu_2024_goda.shtml",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "155",
				// 		"title": "Промышленно-энергетический форум TNF Forum",
				// 		"backgroundColor": "#E21D5A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E21D5A",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-18T00:00:00",
				// 		"end": "2023-09-21T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://oilgasforum.ru/",
				// 		"terms": [
				// 			"Форум"
				// 		]
				// 	},
				// 	{
				// 		"id": "159",
				// 		"title": "Старт «Урока цифры» от Сбера",
				// 		"backgroundColor": "#1E39B1",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#1E39B1",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-18T00:00:00",
				// 		"end": "2023-09-18T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://xn--h1adlhdnlo2c.xn--p1ai/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "153",
				// 		"title": "Международный форум Kazan Digital Week",
				// 		"backgroundColor": "#E21D5A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E21D5A",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-20T00:00:00",
				// 		"end": "2023-09-22T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://kazandigitalweek.com/ru/site",
				// 		"terms": [
				// 			"Форум"
				// 		]
				// 	},
				// 	{
				// 		"id": "344",
				// 		"title": "Цифровизация транспорта",
				// 		"backgroundColor": "#536CB1",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#536CB1",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-21T00:00:00",
				// 		"end": "2023-09-21T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://eventtoday.biz/digital-transport-2023",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "350",
				// 		"title": "Форум «Управление данными 2023»",
				// 		"backgroundColor": "#3BD8E7",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#3BD8E7",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-21T00:00:00",
				// 		"end": "2023-09-21T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.osp.ru/lp/dm2023",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "341",
				// 		"title": "BIS SUMMIT 2023",
				// 		"backgroundColor": "#4775C3",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#4775C3",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-21T00:00:00",
				// 		"end": "2023-09-21T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.bissummit.ru/programma?utm_campaign=digest080923&utm_medium=email&utm_source=Sendsay",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "240",
				// 		"title": "ИННОПРОМ. Казахстан",
				// 		"backgroundColor": "#E21D5A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E21D5A",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-25T00:00:00",
				// 		"end": "2023-09-29T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://expo.russiaqazaqstan.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "242",
				// 		"title": "ФОРУМ Развитие и регулирование экосистем в России: на пути к цифровому равенству",
				// 		"backgroundColor": "#4B529C",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#4B529C",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-26T00:00:00",
				// 		"end": "2023-09-26T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://eventtoday.biz/ecosystems",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "152",
				// 		"title": "Э+Аналитика",
				// 		"backgroundColor": "#3CCF46",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#3CCF46",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-27T00:00:00",
				// 		"end": "2023-09-27T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/je-analitika/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "244",
				// 		"title": "ИТОПК-2023",
				// 		"backgroundColor": "#70418A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#70418A",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-27T00:00:00",
				// 		"end": "2023-09-29T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://итопк.рф/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "157",
				// 		"title": "Форум SatCommRus",
				// 		"backgroundColor": "#A43A78",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#A43A78",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-28T00:00:00",
				// 		"end": "2023-09-29T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://satcomrus.ru/",
				// 		"terms": [
				// 			"Форум"
				// 		]
				// 	},
				// 	{
				// 		"id": "184",
				// 		"title": "Демо-день ИЦК «Нефтегаз, нефтехимия и недропользование»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-09-28T00:00:00",
				// 		"end": "2023-09-28T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://byteoilconf.ru/2023",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "246",
				// 		"title": "Инфофорум-Центр",
				// 		"backgroundColor": "#5BD3C3",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#5BD3C3",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-03T00:00:00",
				// 		"end": "2023-10-06T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://infoforum.ru/infoforum-centr",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "248",
				// 		"title": "World Congress on Innovation &amp; Technology",
				// 		"backgroundColor": "#0921A5",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#0921A5",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-04T00:00:00",
				// 		"end": "2023-10-06T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://wcit2023.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "179",
				// 		"title": "Демо-день ИЦК АПК",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-06T00:00:00",
				// 		"end": "2023-10-06T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://ick-apk.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "250",
				// 		"title": "Микроэлектроника 2023",
				// 		"backgroundColor": "#6A57A1",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#6A57A1",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-09T00:00:00",
				// 		"end": "2023-10-14T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://microelectronica.pro/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "252",
				// 		"title": "Форум «Большие смыслы»",
				// 		"backgroundColor": "#D1B82B",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#D1B82B",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-10T00:00:00",
				// 		"end": "2023-10-13T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://большиесмыслы.рф/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "191",
				// 		"title": "Демо-день ИЦК «Экология»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-10T00:00:00",
				// 		"end": "2023-10-10T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://reo.ru/ref2023/#registration",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "147",
				// 		"title": "Демо-день ИЦК «Мобильная связь»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-12T00:00:00",
				// 		"end": "2023-10-12T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "http://мобильнаясвязь.рф/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "186",
				// 		"title": "Демо-день ИЦК «Двигателестроение»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-12T00:00:00",
				// 		"end": "2023-10-12T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://ицк-двигателестроение.рф/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "254",
				// 		"title": "GITEX GLOBAL",
				// 		"backgroundColor": "#70418A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#70418A",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-16T00:00:00",
				// 		"end": "2023-10-20T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.gitex.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "256",
				// 		"title": "Старт «Урока цифры» от VK",
				// 		"backgroundColor": "#1A2CB4",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#1A2CB4",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-16T00:00:00",
				// 		"end": "2023-10-16T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://datalesson.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "260",
				// 		"title": "Конференция «Инфраструктура цифровизации 2023»",
				// 		"backgroundColor": "#C36A33",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#C36A33",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-18T00:00:00",
				// 		"end": "2023-10-18T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.osp.ru/lp/infrastructure2023",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "262",
				// 		"title": "Финтех",
				// 		"backgroundColor": "#555353",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#555353",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-18T00:00:00",
				// 		"end": "2023-10-18T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://events.vedomosti.ru/events/fin23",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "258",
				// 		"title": "ИНФОТЕХ",
				// 		"backgroundColor": "#CF2B64",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#CF2B64",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-18T00:00:00",
				// 		"end": "2023-10-19T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://infotex72.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "311",
				// 		"title": "Форум «Сделано в России»",
				// 		"backgroundColor": "#4543CE",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#4543CE",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-19T00:00:00",
				// 		"end": "2023-10-20T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://forum.exportcenter.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "313",
				// 		"title": "Digital Innopolis Days",
				// 		"backgroundColor": "#A96DC0",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#A96DC0",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-19T00:00:00",
				// 		"end": "2023-10-20T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://digitalinnopolisdays.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "315",
				// 		"title": "PromSpace 2023",
				// 		"backgroundColor": "#CEA55B",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#CEA55B",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-23T00:00:00",
				// 		"end": "2023-10-23T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://nationalkongress.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "317",
				// 		"title": "Спектр Форум",
				// 		"backgroundColor": "#1E91D0",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#1E91D0",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-24T00:00:00",
				// 		"end": "2023-10-25T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://spectr-forum.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "330",
				// 		"title": "Конференция «Цифровые международные отношения»",
				// 		"backgroundColor": "#3957A1",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#3957A1",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-25T00:00:00",
				// 		"end": "2023-10-26T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://mgimodigital.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "332",
				// 		"title": "Форум Кадры для цифровой экономики России: из 2023 в 2030",
				// 		"backgroundColor": "#CE6868",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#CE6868",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-25T00:00:00",
				// 		"end": "2023-10-25T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://eventtoday.biz/digital-personnel-economy",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "335",
				// 		"title": "HR*-клуб: цифровизация кадров и компетенции будущего",
				// 		"backgroundColor": "#7C7C7C",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#7C7C7C",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-25T00:00:00",
				// 		"end": "2023-10-25T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://events.vedomosti.ru/events/spb_HR_club_cifrovizacia_kadrov",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "337",
				// 		"title": "Демо-день ИЦК «Торговля»",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-26T00:00:00",
				// 		"end": "2023-10-26T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://ицкторговля.рф/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "347",
				// 		"title": "Форум «Интеллектуальная автоматизация. ИИ внутри компании»",
				// 		"backgroundColor": "#A02DA0",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#A02DA0",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-26T00:00:00",
				// 		"end": "2023-10-26T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.osp.ru/lp/ia2023",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "345",
				// 		"title": "Форум RBC.TECH: Технологии, меняющие бизнес",
				// 		"backgroundColor": "#2B8027",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#2B8027",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-26T00:00:00",
				// 		"end": "2023-10-26T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://events.rbc.ru/event/638daac69a7947c0320ea94b",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "319",
				// 		"title": "India Mobile Congress",
				// 		"backgroundColor": "#CF2B64",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#CF2B64",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-27T00:00:00",
				// 		"end": "2023-10-29T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.indiamobilecongress.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "351",
				// 		"title": "Бизнес-форум «Цифровой девелопмент»",
				// 		"backgroundColor": "#2B96CA",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#2B96CA",
				// 		"color": "#ffffff",
				// 		"start": "2023-10-31T00:00:00",
				// 		"end": "2023-10-31T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://eventtoday.biz/digital-development-forum",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "220",
				// 		"title": "China International Import Expo (CIIE-2022)",
				// 		"backgroundColor": "#E21D5A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E21D5A",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-05T00:00:00",
				// 		"end": "2023-11-10T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.ciie.org/zbh/en/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "222",
				// 		"title": "ИТ-диалог",
				// 		"backgroundColor": "#1E91D0",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#1E91D0",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-09T00:00:00",
				// 		"end": "2023-11-11T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://итдиалог.рф/2023_autumn/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "224",
				// 		"title": "Международный муниципальный форум стран Brics",
				// 		"backgroundColor": "#3957A1",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#3957A1",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-09T00:00:00",
				// 		"end": "2023-11-10T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://imbricsforum.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "226",
				// 		"title": "Форум Цифротех",
				// 		"backgroundColor": "#306093",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#306093",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-09T00:00:00",
				// 		"end": "2023-11-10T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://ctexpo.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "228",
				// 		"title": "Web Summit 2023",
				// 		"backgroundColor": "#70418A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#70418A",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-13T00:00:00",
				// 		"end": "2023-11-16T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://websummit.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "230",
				// 		"title": "Cairo ICT 2023",
				// 		"backgroundColor": "#DF99E2",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#DF99E2",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-19T00:00:00",
				// 		"end": "2023-11-22T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://cairoict.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "232",
				// 		"title": "IoT Harvest 2023",
				// 		"backgroundColor": "#DD9F57",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#DD9F57",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-21T00:00:00",
				// 		"end": "2023-11-21T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://iotas.ru/events/6474c652bad44c6396c91b64",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "236",
				// 		"title": "Конференция в сфере технологий и искусственного интеллекта — AI Journey",
				// 		"backgroundColor": "#34C4C4",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#34C4C4",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-22T00:00:00",
				// 		"end": "2023-11-24T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://aij.ru/conference",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "238",
				// 		"title": "Старт марафона встреч ИТ-компаний с отраслевыми вузами «СХ»",
				// 		"backgroundColor": "#A43A78",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#A43A78",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-22T00:00:00",
				// 		"end": "2023-11-22T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://datapeople.ru/dp17",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "234",
				// 		"title": "Церемония награждения и объявление победителей «Премия искусственного интеллека»",
				// 		"backgroundColor": "#3FDF4A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#3FDF4A",
				// 		"color": "#ffffff",
				// 		"start": "2023-11-23T00:00:00",
				// 		"end": "2023-11-23T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://ai-awards.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "201",
				// 		"title": "Международная выставка-форум «Россия»",
				// 		"backgroundColor": "#0042AA",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#0042AA",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-01T00:00:00",
				// 		"end": "2023-12-01T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://russia.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "203",
				// 		"title": "ИТ-конгресс и выставка «Форум будущего»",
				// 		"backgroundColor": "#E31048",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E31048",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-05T00:00:00",
				// 		"end": "2023-12-07T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://forum-future.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "205",
				// 		"title": "Премия «ТОП-1000 российских менеджеров»",
				// 		"backgroundColor": "#D1AA6D",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#D1AA6D",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-05T00:00:00",
				// 		"end": "2023-12-05T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://award.amr.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "207",
				// 		"title": "Cyber Day: конференция по кибербезопасности Skolkovo CyberDay 2023",
				// 		"backgroundColor": "#B1EC52",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#B1EC52",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-08T00:00:00",
				// 		"end": "2023-12-08T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://cyberhub.sk.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "210",
				// 		"title": "Comnews Awards",
				// 		"backgroundColor": "#532E4F",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#532E4F",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-08T00:00:00",
				// 		"end": "2023-12-08T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.comnews-conferences.ru/ru/conference/awards2023",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "215",
				// 		"title": "Конференция «Э+» Регионы",
				// 		"backgroundColor": "#00FE89",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#00FE89",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-11T00:00:00",
				// 		"end": "2023-12-11T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://digital-economy.timepad.ru/event/2706405/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "274",
				// 		"title": "Конференция «Э+» Искусственный интеллект",
				// 		"backgroundColor": "#00FE89",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#00FE89",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-12T00:00:00",
				// 		"end": "2023-12-12T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://d-economy.ru/news/12-dekabrja-v-15-00-na-ploshhadke-ano-cifrovaja-jekonomika-projdet-konferencija-je-ii/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "213",
				// 		"title": "Медиа-брифинг АНО «Цифровая экономика»: итоги года",
				// 		"backgroundColor": "#35B5E9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#35B5E9",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-13T00:00:00",
				// 		"end": "2023-12-13T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://d-economy.ru/news/ano-cifrovaja-jekonomika-prezentuet-analiticheskie-itogi-goda/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "199",
				// 		"title": "Премия Рунета 2023",
				// 		"backgroundColor": "#2D2D2D",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#2D2D2D",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-15T00:00:00",
				// 		"end": "2023-12-15T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://premiaruneta.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "218",
				// 		"title": "Форум «ЦИФРОВАЯ ТРАНСПОРТАЦИЯ»",
				// 		"backgroundColor": "#D333E1",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#D333E1",
				// 		"color": "#ffffff",
				// 		"start": "2023-12-19T00:00:00",
				// 		"end": "2023-12-19T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://transportforum.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "267",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-01-31T00:00:00",
				// 		"end": "2024-01-31T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "264",
				// 		"title": "Игры будущего",
				// 		"backgroundColor": "#B240BB",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#B240BB",
				// 		"color": "#ffffff",
				// 		"start": "2024-02-21T00:00:00",
				// 		"end": "2024-03-03T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://gofuture.games/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "271",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-02-28T00:00:00",
				// 		"end": "2024-02-28T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-2/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "269",
				// 		"title": "Всемирный фестиваль молодёжи",
				// 		"backgroundColor": "#FDBE15",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#FDBE15",
				// 		"color": "#ffffff",
				// 		"start": "2024-03-01T00:00:00",
				// 		"end": "2024-03-07T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://fest2024.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "273",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-03-27T00:00:00",
				// 		"end": "2024-03-27T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-3/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "286",
				// 		"title": "RIGF 2024",
				// 		"backgroundColor": "#5D4A9A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#5D4A9A",
				// 		"color": "#ffffff",
				// 		"start": "2024-04-09T00:00:00",
				// 		"end": "2024-04-10T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://rigf2024.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "288",
				// 		"title": "Иннопром. Центральная Азия",
				// 		"backgroundColor": "#E21D5A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E21D5A",
				// 		"color": "#ffffff",
				// 		"start": "2024-04-22T00:00:00",
				// 		"end": "2024-04-24T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://tashkent.bigindustrialweek.com/ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "276",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-04-24T00:00:00",
				// 		"end": "2024-04-24T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-4/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "291",
				// 		"title": "XV Международный экономический форум «Россия – исламский мир: KAZANFORUM»",
				// 		"backgroundColor": "#46A08C",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#46A08C",
				// 		"color": "#ffffff",
				// 		"start": "2024-05-14T00:00:00",
				// 		"end": "2024-05-19T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://kazanforum.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "294",
				// 		"title": "Международный форум «ТИБО»",
				// 		"backgroundColor": "#CE34C5",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#CE34C5",
				// 		"color": "#ffffff",
				// 		"start": "2024-05-21T00:00:00",
				// 		"end": "2024-05-24T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://tibo.by/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "300",
				// 		"title": "РИФ 2024",
				// 		"backgroundColor": "#E4D35A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E4D35A",
				// 		"color": "#ffffff",
				// 		"start": "2024-05-22T00:00:00",
				// 		"end": "2024-05-24T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://russianinternetforum.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "277",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-05-29T00:00:00",
				// 		"end": "2024-05-29T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-5/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "303",
				// 		"title": "Петербургский международный экономический форум (ПМЭФ)",
				// 		"backgroundColor": "#3957A1",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#3957A1",
				// 		"color": "#ffffff",
				// 		"start": "2024-06-06T00:00:00",
				// 		"end": "2024-06-08T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://forumspb.com/?lang=ru",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "278",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-06-26T00:00:00",
				// 		"end": "2024-06-26T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-6/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "306",
				// 		"title": "ИННОПРОМ",
				// 		"backgroundColor": "#E21D5A",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E21D5A",
				// 		"color": "#ffffff",
				// 		"start": "2024-07-08T00:00:00",
				// 		"end": "2024-07-11T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://expo.innoprom.com/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "309",
				// 		"title": "VK Fest",
				// 		"backgroundColor": "#5CA1BD",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#5CA1BD",
				// 		"color": "#ffffff",
				// 		"start": "2024-07-20T00:00:00",
				// 		"end": "2024-07-21T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://vk.com/fest",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "279",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-07-31T00:00:00",
				// 		"end": "2024-07-31T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-7/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "280",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-08-28T00:00:00",
				// 		"end": "2024-08-28T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-8/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "290",
				// 		"title": "Восточный Экономический Форум",
				// 		"backgroundColor": "#027EBC",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#027EBC",
				// 		"color": "#ffffff",
				// 		"start": "2024-09-03T00:00:00",
				// 		"end": "2024-09-06T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://forumvostok.ru/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "282",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-09-25T00:00:00",
				// 		"end": "2024-09-25T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-9/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "305",
				// 		"title": "GITEX GLOBAL 2024",
				// 		"backgroundColor": "#E045DC",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#E045DC",
				// 		"color": "#ffffff",
				// 		"start": "2024-10-14T00:00:00",
				// 		"end": "2024-10-18T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://www.gitex.com/home",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "283",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-10-30T00:00:00",
				// 		"end": "2024-10-30T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-10/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "284",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-11-27T00:00:00",
				// 		"end": "2024-11-27T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-11/",
				// 		"terms": [
				// 			""
				// 		]
				// 	},
				// 	{
				// 		"id": "285",
				// 		"title": "Цифровая среда",
				// 		"backgroundColor": "#43A2C9",
				// 		"textColor": "#ffffff",
				// 		"borderColor": "#43A2C9",
				// 		"color": "#ffffff",
				// 		"start": "2024-12-25T00:00:00",
				// 		"end": "2024-12-25T20:59:59",
				// 		"display": "block",
				// 		"showtime": 1,
				// 		"ts": 0.3333333333333333,
				// 		"image_src": "",
				// 		"url": "https://calendar.d-economy.ru/meropr/cifrovaja-sreda-12/",
				// 		"terms": [
				// 			""
				// 		]
				// 	}
				// ]

				// successCallback(doc);
				// findEarlistDate(doc);

				$.ajax({
					type: "post",
					data: {PAGE: 1, FILTER: 1},
					dataType: "json",
					url: '/ajax/getevents.php',
					success: function (data) {
						successCallback(data);
						if (innerWidth <= 1024) {
							findEarlistDate(data);
						} else {
							checkPlacesCard();
						}
					},
					error: function (data) {
						console.log(data);
					}
				});
			},
			eventSourceSuccess: function() {
				setTimeout(() => {
					$('.fc-list-table .fc-list-day').each(function () {
						$(this).prev('.fc-list-event').addClass('is-last');
					})
				},50)
			},
		});
		window.calendar = calendar;
		calendar.render();		

		function gotoDate() {
			if (!nowDate) {
				setTimeout(() => {
					gotoDate();
				}, 300);
			} else {
				calendar.gotoDate(nowDate);
			}
		}
		gotoDate();

		if (innerWidth <= 1024) {
			$('#select-events').on('change', function() {
				let selectedValue = $(this).val();							
				switch (selectedValue) {
					case '2':
						calendar.gotoDate(earliestEvent.start)
						break;
					case '1':
						calendar.gotoDate(nowDate)
						break;		
					default:
						break;
				}
			});
		}
		
		$(document).on('click', '.fc-popover-close, .fc-popover-bg', function (e) {
			$('.fc-popover').remove();
		});

		if (jQuery(window).width() < 1025) {
			$('.fc-list-table .fc-list-day').each(function () {
				$(this).prev('.fc-list-event').addClass('is-last');
			})
		}

		jQuery(window).resize(function () {
			calendar.refetchEvents();

			if (jQuery(window).width() < 1025) {
				$('.fc-list-table .fc-list-day').each(function () {
					$(this).prev('.fc-list-event').addClass('is-last');
				})
			}
		});

		$(document).on('click', '.fc-event-main-link', function (e) {
			e.preventDefault();

			let dayCell = $(this).closest('.fc-daygrid-day');
			let dayCellDate = $(this).data('date');
			let dayCellDateString = new Date(dayCellDate).toDateString();

			let events = calendar.getEvents();
			let dayEvents = events.filter(function (event) {
				let start = new Date(event._instance.range.start).toDateString();
				let end = new Date(event._instance.range.end).toDateString();

				return Date.parse(start) <= Date.parse(dayCellDateString) && Date.parse(end) >= Date.parse(dayCellDateString);
			});
			let sortedEvents = dayEvents.sort(function(a, b) {
				let durationA = moment(a.end).diff(moment(a.start));
				let durationB = moment(b.end).diff(moment(b.start));
				return durationB - durationA;
			});

			console.log('sortedEvents',sortedEvents)

			let eventCount = dayEvents.length;

			if (dayCell) {
				if (eventCount >= 2) {

					$('.fc-popover').remove();

					let day = $(`.fc-daygrid-day[data-date="${dayCellDate}"]`);
					let dayDate = day.find('.fc-daygrid-day-number').text();

					let eventsHtml = sortedEvents.map((event) => {
						return `
								<div class="fc-daygrid-event-harness">
									<a
										href="${event.url}"
										target="_blank"
										class="fc-daygrid-event fc-daygrid-block-event fc-h-event fc-event fc-event-start fc-event-end fc-event-future"
										style="background-color: ${event.backgroundColor};"
									>
										<div class="fc-event-main">
											<div class="fc-event-main-frame">
												<div class="fc-event-time">09:30 - 17:00</div>
												<div class="fc-event-title-container">
													<div class="fc-event-title fc-sticky">${event.title}</div>
												</div>
											</div>
										</div>
									</a>
								</div>
							`;
					});

					let popup =
						`
								<div class="fc-popover fc-more-popover fc-day fc-day-tue fc-day-future">
									<div class="fc-popover-header ">
										<span class="fc-popover-title">${dayDate}</span>
										<span class="fc-popover-close fc-icon fc-icon-x"></span>
									</div>
									<div class="fc-popover-body">${eventsHtml.join('')}</div>
									<div class="fc-popover-bg"></div>
								</div>
						`;

					day.append(popup);

					let popover = $('.fc-popover');

					let dayRect = day[0].getBoundingClientRect();
					let containerRect = $('.fc-daygrid-body')[0].getBoundingClientRect();
					let hoverTop;
					let hoverLeft;

					if (dayRect.bottom + popover.outerHeight() > containerRect.bottom) {
						hoverTop = (dayRect.bottom - dayRect.top) + dayRect.top - containerRect.top - popover.outerHeight() - 10; // нижняя граница
					} else {
						hoverTop = dayRect.top - containerRect.top; // верхняя граница
					}

					if (dayRect.right + popover.outerWidth() > containerRect.right) {
						hoverLeft = dayRect.left - containerRect.left - popover.outerWidth() - 10; // Влево
					} else if (dayRect.left - popover.outerWidth() < containerRect.left) {
						hoverLeft = dayRect.left - containerRect.left + dayRect.width; // Вправо
					} else {
						hoverLeft = dayRect.left - containerRect.left + dayRect.width; // Вправо
					}

					popover.css({ top: hoverTop, left: hoverLeft });

				} else {
					window.open($(this).attr('href'), "_blank");
				}
			}
		})

		$('.fc-prev-button').click(function() {
			if (jQuery(window).width() > 1024) {
				// calendar.prev();
				/*if (calendar.view.type !== 'listWeek') {
					calendar.changeView('listWeek');
				} else {
					calendar.prev();
				}*/
			}
		});

		$('.fc-next-button').click(function() {
			if (jQuery(window).width() > 1024) {
				// calendar.next();
				/*if (calendar.view.type !== 'listWeek') {
					calendar.changeView('listWeek');
					calendar.next();
				} else {
				}*/
			}
		});
	}
}

function destroy(container) {

}

export default {
	init,
	destroy,
};
