const setLimitationError = (limitation) => {
	// eslint-disable-next-line no-console
	console.error(`Переданный формат ограничения(data-limitation="${limitation}") - не поддерживается. Проверьте корректность введённых значений.`);
};

export const getLimitationsRegEx = (limitation) => {
	switch (limitation) {
		case 'digit':
			return /[^\d]/g;
		case 'name':
			return /[^a-zA-Zа-яёА-ЯЁ\-\s\d!@#$%^&*()_+=~`<>?,./;:'"\\|{}[\]]/g
		case 'letters':
			return /[^a-zA-Zа-яёА-ЯЁ\s]/g;
		case 'letters-and-digit':
			return /[^a-zA-Zа-яёА-ЯЁ\s\d]/g;
		case 'cyrillic':
			return /[^а-яёА-ЯЁ\s]/g;
		case 'latin':
			return /[^a-zA-Z\s]/g;
		default:
			return setLimitationError(limitation);
	}
};

export const getMatrixLimitationsRegEx = (matrix) => {
	switch (matrix) {
		case 'digit':
			return /[^\d]/g;
		case 'name':
			// eslint-disable-next-line
			return /[^\а-яё\А-ЯЁ\a-z\A-Z\-]]/g;
		case 'letters':
			// eslint-disable-next-line
			return /[^\а-яё\А-ЯЁ\a-z\A-Z]/g;
		case 'letters-and-digit':
			// eslint-disable-next-line
			return /[^\а-яё\А-ЯЁ\a-z\A-Z\d]/g;
		case 'cyrillic':
			// eslint-disable-next-line
			return /[^\а-яё\А-ЯЁ]/g;
		case 'latin':
			// eslint-disable-next-line
			return /[^\a-z\A-Z]/g;
		default:
			return false;
	}
};
// eslint-disable-next-line
export const getMailRegEx = () => /[a-zA-Zа-яёА-ЯЁ0-9]{1}([a-zA-Zа-яёА-ЯЁ0-9\-_\.]{1,})?@[a-zA-Zа-яёА-ЯЁ0-9\-]{1}([a-zA-Zа-яёА-ЯЁ0-9.\-]{1,})?[a-zA-Zа-яёА-ЯЁ0-9\-]{1}\.[a-zA-Zа-яёА-ЯЁ]{2,6}/;
